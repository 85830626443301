<template>
  <section class="video-block">
    <div class="container">
      <h2
        v-if="values.heading"
        class="video-block__title"
        v-html="values.heading"
      />
    </div>
    <div
      ref="videoWrapRef"
      class="video-block__video"
    >
      <video
        v-if="isShowVideoFrame"
        ref="refVideo"
        loop
        :controls="false"
        :poster="previewImageUrl"
      >
        <source :src="extras.video" />
      </video>
      <div
        v-if="!isPlayVideo"
        class="video-block__play"
        @click="startVideo"
      >
        <img
          src="~/assets/img/svg/common/play.svg"
          alt="старт видео"
        >
      </div>
    </div>
  </section>
</template>

<script lang="ts" setup>
import { ref } from 'vue';
import { useIntersectionObserver } from '@vueuse/core';
import type { IBlockDefaultProps } from '#sitis/internal/models/common';

const props = defineProps<IBlockDefaultProps>();
const $img = useImage();

const refVideo: any = ref(null);
const isPlayVideo = ref(false);
const videoWrapRef = ref<HTMLDivElement>();
const isShowVideoFrame = ref(false);

const previewImageUrl = computed(() => {
  if (!props?.values?.image) {
    return;
  }
  return $img(String(props?.values?.image), {
    width: 1920
  });
});

const startVideo = () => {
  if (!props.extras.video) {
    return;
  }
  // if (window?.innerWidth < 1024) {
  //   return;
  // }
  if (!refVideo.value) {
    setTimeout(() => {
      startVideo();
    }, 500);
    return;
  }
	refVideo.value!.play();
  refVideo.value.controls = true;
	isPlayVideo.value = true;
};

const { stop } = useIntersectionObserver(
  videoWrapRef,
  ([{ isIntersecting }]) => {
    if (isIntersecting) {
      isShowVideoFrame.value = true;
      stop();
    }
  }
);
</script>

<style lang="scss" scoped>
.video-block {
	padding: 64px 0;
	padding-bottom: 0;
	margin-bottom: -32px;
	box-sizing: border-box;
}

.video-block__title {
	margin-bottom: 32px;
}

.video-block__video {
	position: relative;
	overflow: hidden;
	border-radius: 30px 30px 0 0;
	background: linear-gradient(0deg, rgb(0 0 0 / 20%) 0%, rgb(0 0 0 / 20%) 100%), lightgray 50% / cover no-repeat;

	video {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		object-fit: cover;
	}

	&::after {
		content: "";
		float: left;
		padding-top: 46.25%;
	}
}

.video-block__play {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: 100px;
	height: 100px;
	border-radius: 100%;
	background-color: #F4F3F1;
	cursor: pointer;

	img {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}
}

@media (max-width: 1023px) {
	.video-block {
		padding: 50px 0 0 0;
	}
	.video-block__title {
		margin-bottom: 24px;
	}
	.video-block__video {
		border-radius: 24px 24px 0 0;

		&:after {
			padding-top: 74.25%;
		}
	}
	.video-block__play {
		width: 50px;
		height: 50px;

		img {
			width: 11px;
		}
	}
}
</style>
